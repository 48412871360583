<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Leave Summary
        <v-spacer></v-spacer>
        <v-sheet max-width="120">
          <v-autocomplete
            auto-select-first
            filled
            flat
            v-model="selecterYear"
            :items="years"
            outlined
            dense
            hide-details
            @change="fetchData()"
          ></v-autocomplete>
        </v-sheet>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Leave Applications',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Leave Applications',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your leave summary</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View leave summary of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all leave summary</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>

      <v-row>
        <v-col cols="12" md="6" lg="8">
          <v-row class="pa-5">
            <v-col cols="12">
              <v-card outlined>
                <v-list-item three-line>
                  <v-list-item-content class="text-center">
                    <v-sheet
                      class="text-h5 mb-1 d-flex justify-center align-center text-left"
                      color="transparent"
                    >
                      <v-sheet
                        class="primary--text text-h4 me-2"
                        color="transparent"
                      >
                        {{
                          casualPastCount +
                          annualPastCount +
                          sickPastCount +
                          lieuPastCount +
                          nopayPastCount
                        }}
                      </v-sheet>
                      Total Approved and Availed Leaves
                    </v-sheet>
                    <v-sheet color="transparent">
                      <v-chip class="ma-1" dark small color="green">
                        {{ casualPastCount }} Casual</v-chip
                      >
                      <v-chip class="ma-1" dark small color="cyan">
                        {{ annualPastCount }} Annual</v-chip
                      >
                      <v-chip class="ma-1" dark small color="orange">
                        {{ sickPastCount }} Sick</v-chip
                      >
                      <v-chip class="ma-1" dark small color="teal"
                        >{{ lieuPastCount }} Lieu</v-chip
                      >
                      <v-chip class="ma-1" dark small color="red">
                        {{ nopayPastCount }} No-pay</v-chip
                      >
                    </v-sheet>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1">
                      Casual Leaves
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ casualEligCount }} Total Eligible
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ casualPastCount }} Approved and Availed
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ casualUpcomCount }} Approved Upcoming
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    class="d-flex flex-column justify-center white--text"
                    tile
                    size="80"
                    color="green"
                  >
                    <span class="text-h5">{{ casualAppliCount }}</span>
                    <span class="text-caption">Available</span>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1">
                      Annual Leaves
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ annualEligCount }} Total Eligible
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ annualPastCount }} Approved and Availed
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ annualUpcomCount }} Approved Upcoming
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    class="d-flex flex-column justify-center white--text"
                    tile
                    size="80"
                    color="cyan"
                  >
                    <span class="text-h5">{{ annualAppliCount }}</span>
                    <span class="text-caption">Available</span>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1">
                      Sick Leaves
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ sickEligCount }} Total Eligible
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ sickPastCount }} Approved and Availed
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ sickUpcomCount }} Approved Upcoming
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    class="d-flex flex-column justify-center white--text"
                    tile
                    size="80"
                    color="orange"
                  >
                    <span class="text-h5">{{ sickAppiCount }}</span>
                    <span class="text-caption">Available</span>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1">
                      Lieu Leaves
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ lieuEligCount }} Total Eligible
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ lieuPastCount }} Approved and Availed
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ liueUpcomCount }} Approved Upcoming
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    class="d-flex flex-column justify-center white--text"
                    tile
                    size="80"
                    color="teal"
                  >
                    <span class="text-h5">{{ lieuAppiCount }}</span>
                    <span class="text-caption">Available</span>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="d-flex justify-center align-center mb-5"
        >
          <v-card flat outlined max-width="300">
            <v-date-picker
              no-title
              class="mt-5"
              color="primary"
              :events="calendarEvents"
            ></v-date-picker>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import {
  fetchAndStoreMyLeaveApplications,
  fetchAndStoreMyLieuApplications,
} from "@/services/myDataService";

import constants from "@/constants.json";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    tab: 1,
    loading: false,
    years: [],
    myLieus: [],
    myLeaves: [],
    selecterYear: 0,
    casualAppliCount: 0,
    casualPastCount: 0,
    casualUpcomCount: 0,
    casualEligCount: 0,
    annualAppliCount: 0,
    annualPastCount: 0,
    annualUpcomCount: 0,
    annualEligCount: 0,
    sickAppiCount: 0,
    sickPastCount: 0,
    sickUpcomCount: 0,
    sickEligCount: 0,
    lieuAppiCount: 0,
    lieuPastCount: 0,
    liueUpcomCount: 0,
    liueEligCount: 0,
    nopayPastCount: 0,
    nopayUpcomCount: 0,
  }),
  async created() {
    this.selecterYear = new Date().getFullYear();
    await this.fetchData();
  },
  mounted() {
    //
  },
  methods: {
    changeView() {
      if (this.tab == 2) {
        this.$router.push({
          name: "PageAllLeaveSummary",
        });
      }
      if (this.tab == 3) {
        this.$router.push({
          name: "PageSubLeaveSummary",
        });
      }
    },
    async fetchData() {
      this.loading = true;
      for (let i = 0; i < 5; i++) {
        this.years.push(this.selecterYear - i);
      }
      this.myLeaves = await fetchAndStoreMyLeaveApplications(
        false,
        this.$_getFirstDateOfAYear(this.selecterYear),
        this.$_getLastDateOfAYear(this.selecterYear),
        '["APPROVED"]'
      );
      this.myLieus = await fetchAndStoreMyLieuApplications(
        false,
        this.$_getFirstDateOfAYear(this.selecterYear),
        this.$_getLastDateOfAYear(this.selecterYear),
        '["APPROVED"]'
      );

      await this.calculateAnualLeaves();
      await this.calculateCasualLeaves();
      await this.calculateSickLeaves();
      await this.calculateLieuLeaves();
      await this.calculateNopayLeaves();

      this.loading = false;
    },
    async calculateCasualLeaves() {
      let casualLeaves = this.myLeaves.filter((item) => item.type == "CASUAL");
      this.casualPastCount = 0;
      this.casualUpcomCount = 0;

      casualLeaves.forEach((element) => {
        if (new Date(element.from_date) > new Date()) {
          this.casualUpcomCount += Number(element.no_of_days);
        } else {
          this.casualPastCount += Number(element.no_of_days);
        }
      });

      if (this.auth.type == "Regular Contract Staff") {
        this.casualEligCount = (
          (Math.min(
            this.$_calculateMonthsWorked(
              this.auth.date_of_joining,
              new Date().getFullYear() != this.selecterYear
                ? new Date(this.$_getLastDateOfAYear(this.selecterYear))
                : new Date()
            ),
            12
          ) /
            12) *
          constants.leaves.casual
        ).toFixed(2);
      }

      this.casualAppliCount =
        this.casualEligCount - this.casualPastCount - this.casualUpcomCount;
    },
    async calculateAnualLeaves() {
      let annualLeaves = this.myLeaves.filter((item) => item.type == "ANNUAL");
      this.annualPastCount = 0;
      this.annualUpcomCount = 0;

      annualLeaves.forEach((element) => {
        if (new Date(element.from_date) > new Date()) {
          this.annualUpcomCount += Number(element.no_of_days);
        } else {
          this.annualPastCount += Number(element.no_of_days);
        }
      });

      if (this.auth.type == "Regular Contract Staff") {
        this.annualEligCount = (
          (Math.min(
            this.$_calculateMonthsWorked(
              this.auth.date_of_joining,
              new Date().getFullYear() != this.selecterYear
                ? new Date(this.$_getLastDateOfAYear(this.selecterYear))
                : new Date()
            ),
            12
          ) /
            12) *
          constants.leaves.annual
        ).toFixed(2);
      }

      this.annualAppliCount =
        this.annualEligCount - this.annualPastCount - this.annualUpcomCount;
    },
    async calculateSickLeaves() {
      let sickLeaves = this.myLeaves.filter((item) => item.type == "SICK");
      this.sickPastCount = 0;
      this.sickUpcomCount = 0;

      sickLeaves.forEach((element) => {
        if (new Date(element.from_date) > new Date()) {
          this.sickUpcomCount += Number(element.no_of_days);
        } else {
          this.sickPastCount += Number(element.no_of_days);
        }
      });

      if (this.auth.type == "Regular Contract Staff") {
        this.sickEligCount = (
          (Math.min(
            this.$_calculateMonthsWorked(
              this.auth.date_of_joining,
              new Date().getFullYear() != this.selecterYear
                ? new Date(this.$_getLastDateOfAYear(this.selecterYear))
                : new Date()
            ),
            12
          ) /
            12) *
          constants.leaves.sick
        ).toFixed(2);
      }

      this.sickAppiCount =
        this.sickEligCount - this.sickPastCount - this.sickUpcomCount;
    },
    async calculateLieuLeaves() {
      let lieuLeaves = this.myLieus;
      let takenLieuLeaves = this.myLeaves.filter((item) => item.type == "LIEU");
      this.lieuEligCount = 0;
      this.lieuAppiCount = 0;
      this.lieuPastCount = 0;
      this.liueUpcomCount = 0;

      lieuLeaves.forEach((element) => {
        if (
          this.$_calculateDays(
            this.formatDate(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            ),
            element.from_date
          ) >= 0
        ) {
          this.lieuEligCount += Number(element.no_of_days);
        }
      });

      takenLieuLeaves.forEach((element) => {
        if (new Date(element.from_date) > new Date()) {
          this.liueUpcomCount += Number(element.no_of_days);
        } else {
          this.lieuPastCount += Number(element.no_of_days);
        }
      });

      this.lieuAppiCount =
        this.lieuEligCount - this.sickPastCount - this.sickUpcomCount;
    },
    async calculateNopayLeaves() {
      let nopayLeaves = this.myLeaves.filter((item) => item.type == "NOPAY");
      this.nopayPastCount = 0;
      this.nopayUpcomCount = 0;

      nopayLeaves.forEach((element) => {
        if (new Date(element.from_date) > new Date()) {
          this.nopayPastCount += Number(element.no_of_days);
        } else {
          this.nopayUpcomCount += Number(element.no_of_days);
        }
      });
    },

    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, add 1 and pad with 0
      const day = String(date.getDate()).padStart(2, "0"); // Pad day with 0 if necessary
      return `${year}-${month}-${day}`;
    },

    calendarEvents(date) {
      for (let index = 0; index < this.myLeaves.length; index++) {
        const leave = this.myLeaves[index];
        if (leave.from_date == date) {
          return leave.type == "NOPAY"
            ? ["red"]
            : leave.type == "SICK"
            ? ["orange"]
            : leave.type == "CASUAL"
            ? ["green"]
            : leave.type == "LIEU"
            ? ["teal"]
            : ["cyan"];
        }
      }
      return false;
    },
  },
};
</script>